import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import Layout from "../../components/layout"
import Header from "../../components/header"

import { useInView } from "react-intersection-observer"
import Projects from "../../components/projects"

const Typography = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const { elcentroCloudinary } = useStaticQuery(graphql`
    query {
      elcentroCloudinary: allCloudinaryMedia(
        filter: { public_id: { regex: "/rgrq/elcentro/" } }
      ) {
        edges {
          node {
            secure_url
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Header />
      <div className="project">
        <div className="container-fluid subheader">
          <div className="row">
            <div className="col-lg-4">
              <h2>El Centro</h2>
            </div>
            <div className="offset-lg-1 col-lg-3">
              <h2>2020</h2>
            </div>
            <div className="col-lg-4">
              <h2>Typeface Design, Specimen</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-12 hero hero-elcentro">
              <div className={`hero-footer ${inView}`}>
                <div className="row justify-content-between align-items-end">
                  <div className="col-lg-4 d-none d-md-block">
                    {/* <h2>
                      <Link to="/projects/rifla">Previous Project(07) ↖</Link>
                    </h2>
                    <h2>
                      <Link to="/memories">Memories(09) ↗</Link>
                    </h2> */}
                  </div>
                  <div className="offset-md-1 col-md-3 d-none d-md-block">
                    <h2>
                      <Link to="/">rg-rq.com</Link>
                    </h2>
                  </div>
                  <div className="offset-md-2 col-md-2">
                    <button onClick={scrollTop}>
                      <h2>
                        Back up
                        {inView ? (
                          <img
                            className="img-fluid"
                            src="/Up.svg"
                            alt="cheveron icon"
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src="/Up-white.svg"
                            alt="cheveron icon"
                          />
                        )}
                      </h2>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid content">
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Inspiration</p>
            </div>
            <div className="col-lg-7">
              <p className="m-d-60">
                El Centro is a contemporary sans serif typeface inspired by
                mid-century neo-grotesque. Designed as an accessible, yet
                serious sans-serif typeface intended for a multiplicity of use
                functions in body print for Display.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-5">
              <img
                className="img-fluid"
                src={elcentroCloudinary.edges[0].node.secure_url}
                alt=""
              />
              <p>Akzidenz–Grotesk</p>
            </div>
            <div className="col-7">
              <img
                className="img-fluid"
                src={elcentroCloudinary.edges[1].node.secure_url}
                alt=""
              />
              <p>Miller & Richard’s N. 7 release from 1912</p>
            </div>
          </div>
          <div className="row p-t-80">
            <div className="col-12">
              <img
                className="img-fluid"
                src={elcentroCloudinary.edges[2].node.secure_url}
                alt=""
              />
            </div>
            <div className="offset-md-5 col-md-7">
              <p>
                El Centro is a contemporary sans serif typeface inspired by
                mid-century neo-grotesques designed as an accessible, yet
                serious sans-serif typeface intended for a multiplicity of use
                functions in body print for Display.
              </p>
            </div>
          </div>
          <div className="row text-section p-t-200">
            <div className="col-lg-12">
              <h4 className="elcentro-130 text-center">Tacos</h4>
            </div>
            <div className="offset-lg-1 col-lg-5">
              <h4 className="elcentro-100">Asada</h4>
              <h4 className="elcentro-100">Lengua</h4>
              <h4 className="elcentro-100">Carnitas</h4>
              <h4 className="elcentro-100">Pollo</h4>
              <h4 className="elcentro-100">Cabeza</h4>
              <h4 className="elcentro-100">Barbacoa</h4>
              <h4 className="elcentro-100">Suadero</h4>
            </div>
            <div className="offset-lg-1 col-lg-4">
              <h4 className="elcentro-100">Tripa</h4>
              <h4 className="elcentro-100">Chorizo</h4>
              <h4 className="elcentro-100">Rajas</h4>
              <h4 className="elcentro-100">Frijol</h4>
              <h4 className="elcentro-100">Nopal</h4>
              <h4 className="elcentro-100">Queso</h4>
              <h4 className="elcentro-100">Camarón</h4>
            </div>
          </div>

          <div className="row p-t-200">
            <div className="col-lg-12">
              <h4 className="elcentro-130 text-center">
                TORTILLAS
                <br />
                DE MAÍZ
                <br />
                Y HARINA
                <br />
                HECHAS
                <br />
                MANO
              </h4>
            </div>
            <div className="col-lg-12 p-t-80 ">
              <h4 className="elcentro-4-5">
                Tipografía EL CENTRO De Monterrey, S.A. de C.V.
              </h4>
            </div>
            <div className="col-lg-12 p-t-400 ">
              <h4 className="elcentro-4-5 text-center">
                SALSA, FRIJOLES, JALAPEÑOS, CHILES
                <br />
                ASADOS Y CEBOLLITAS ASADAS SON GRATIS !
              </h4>
            </div>
            <div className="offset-lg-5 col-lg-7 p-t-400 ">
              <img
                className="img-fluid"
                src={elcentroCloudinary.edges[3].node.secure_url}
                alt=""
              />
            </div>
          </div>
          <div className="row p-t-100">
            <div className="col-md-12">
              <h4 className="elcentro-100 text-center">Coca Mexicana</h4>
              <h4 className="elcentro-100 text-center">Caguama </h4>
              <h4 className="elcentro-100 text-center">Manzanita</h4>
              <h4 className="elcentro-100 text-center">Agua Fresca</h4>
            </div>
            <div className="col-md-12 p-t-200">
              <h4 className="elcentro-100 text-center">Cuando te invitan</h4>
              <img
                className="img-fluid"
                src={elcentroCloudinary.edges[4].node.secure_url}
                alt=""
              />
              <h4 className="elcentro-100 text-center">Pero ni ahí tacos!</h4>
            </div>
          </div>

          <div className="row text-section p-t-200">
            <div className="col-lg-5">
              <p>Special Thank You</p>
            </div>
            <div className="col-lg-7">
              <p>Greg Lindy (Lux Typo) and Rolando Castillo (Designer)</p>
            </div>
          </div>
        </div>
      </div>
      <div ref={ref}></div>
      <Projects />
    </Layout>
  )
}
export default Typography
